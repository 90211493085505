import React, {Component} from 'react';
import {SliderComponent} from '@syncfusion/ej2-react-inputs';
import "./Range.css";


const tooltip = {placement: "Before", isVisible: true, showOn: "Always"};

class Range extends Component {

    constructor(props) {
        super(props);
        this.state = {
            min: props.question.min,
            max: props.question.max,
            steps: props.question.steps,
            ticks: {
                placement: "After",
                largeStep: props.question.steps,
                smallStep: 1,
                showSmallTicks: false
            },
            answer: (props.answers && props.answers.length > 0) ? props.answers[0] : {answerNumeric: props.question.min},
            readOnly: props.readOnly

        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(JSON.stringify(prevProps.answers) !== JSON.stringify(this.props.answers) && this.props.answers.length > 0)
            this.setState({answer: this.props.answers[0]})
        if(prevProps.readOnly !== this.props.readOnly)
            this.setState({readOnly: this.props.readOnly})

    }


    render() {
        const value = (this.props.answers && this.props.answers.length > 0) ? this.props.answers[0].answerNumeric : this.state.min
        return (
                <div>
                    <div className="wrap w-75 mx-auto">
                        <SliderComponent
                                value={value}
                                type={"MinRange"}
                                min={this.state.min}
                                max={this.state.max}
                                step={this.state.steps}
                                tooltip={tooltip}
                                ticks={this.state.ticks}
                                showButtons={true}
                                change={this.handleChange}
                                readonly={this.state.readOnly}
                        />
                    </div>
                </div>
        )
    }

    handleChange(target) {
        if(!this.state.readOnly) {
            if(!isNaN(target.value)) {

                const answer = this.state.answer ? this.state.answer :
                        {
                            questionId: this.props.question.id
                        }
                answer.answerNumeric = parseInt(target.value);
                this.setState({
                                  answer: answer
                              })

                if(this.props.handleNewAnswer)
                    this.props.handleNewAnswer([answer])
            }
        }
    }
}

export default Range;