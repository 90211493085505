import React, {Component} from 'react';
import GenericProfile from "../../../images/profile.png";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMars, faTimes, faVenus} from "@fortawesome/free-solid-svg-icons";

class PatientProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patient: props.patient
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(JSON.stringify(prevProps.patient) !== JSON.stringify(this.props.patient))
            this.setState({patient: this.props.patient});
    }

    render() {
        const imgSrc = (this.state.patient && this.state.patient.imageType && this.state.patient.photo) ? this.state.patient.imageType + "," + this.state.patient.photo : GenericProfile;

        return (
                <div className="card pcmh-profile-card">
                    <div className="card-header pcmh-profile-card-header">
                        Profile
                    </div>
                    <div className="card-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <img src={imgSrc} alt={this.state.patient.fullName} className="pcmh-profile-photo"/>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6 text-right font-weight-bold">
                                            Gender:
                                        </div>
                                        <div className="col-md-6">
                                            {this.state.patient.gender === "MALE" && <FontAwesomeIcon icon={faMars} />}
                                            {this.state.patient.gender === "FEMALE" && <FontAwesomeIcon icon={faVenus} />}
                                            {this.state.patient.gender !== "MALE" && this.state.patient.gender !== "FEMALE" && <FontAwesomeIcon icon={faTimes} />}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 text-right font-weight-bold">
                                            Date of Birth:
                                        </div>
                                        <div className="col-md-6">
                                            {this.state.patient.dateOfBirth}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 text-right font-weight-bold">
                                            Phone:
                                        </div>
                                        <div className="col-md-6">
                                            {this.state.patient.phone}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 text-right font-weight-bold">
                                            Email:
                                        </div>
                                        <div className="col-md-6">
                                            {this.state.patient.email}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 text-right font-weight-bold">
                                            PCP:
                                        </div>
                                        <div className="col-md-6">
                                            {this.state.patient.careProvider}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 text-right font-weight-bold">
                                            Clinical Team:
                                        </div>
                                        <div className="col-md-6">
                                            X
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="col-md-6">*/}
                                {/*    <div className="row">*/}
                                {/*        <div className="col-md-12">*/}
                                {/*            {stars ? <Achievements stars={stars}/> : null}*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div className="row pcmh-profile-demographics">*/}
                                {/*        <div className="col-md-12">*/}
                                {/*            {practice}*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div className="row">*/}
                                {/*        <div className="col-md-12 pcmh-profile-demographics">*/}
                                {/*            {email}*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div className="row">*/}
                                {/*        <div className="col-md-12 pcmh-profile-demographics">*/}
                                {/*            P:&nbsp;<NumberFormat value={phone} displayType={'text'}*/}
                                {/*                                  format="(###)###-####"/>&nbsp;&nbsp;&nbsp;*/}
                                {/*            F:&nbsp;<NumberFormat value={fax} displayType={'text'}*/}
                                {/*                                  format="(###)###-####"/>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>

                        </div>
                    </div>
                </div>
        );
    }
}

export default PatientProfile;