import React, {Component} from 'react';
import './TeamMembers.css'
import TeamMemberCard from "./TeamMemberCard";
import {Rest} from "../../../util/rest";
import toast from "../../../util/toast";
import {withUser} from "../../../context/UserContext";

class TeamMembers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profiles: [],
            team: props.team
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.team !== this.state.team) {
            this.setState({team: this.props.team}, () =>
                    this.loadMembers(this.props.team));
        }

    }

    render() {
        const profiles = this.state.profiles;
        return (
                <div className="card pcmh-profile-card">
                    <div className="card-header pcmh-profile-card-header">Team Members</div>
                    <div className="card-body pcmh-profile-card-body">
                        {profiles.map(profile => {
                            return <TeamMemberCard key={profile.id} profile={profile}/>
                        })}
                    </div>
                </div>
        )
    }


    loadMembers(team) {
        if(team) {
            Rest.authFetch(this.props.user, "/rest/team/members/" + team.id).then(response => {
                if(response.status === 401 || response.status === 403) {
                    this.setState({profiles: []});
                    toast.error("Unauthorized!")
                }
                else {
                    this.setState({profiles: response});
                }
            })

                    .catch(err => {
                        console.log(err);
                    })
        }
    }
}

export default withUser(TeamMembers);