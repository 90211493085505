import React, {useEffect} from 'react'
import TextArea from "../../assessment/inputtypes/TextArea";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import AssessmentAnswersTable from "../assessments/AssessmentAnswersTable";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {DeleteOutlined, LinkOutlined} from "@ant-design/icons";
import {Rest} from "../../../../util/rest";
import {toast} from "react-toastify";
import {withUser} from "../../../../context/UserContext";
import AssessmentTemplateQuestionDependencyEditor from "./AssessmentTemplateQuestionDependencyEditor";
import _ from "lodash";
const inputLabelProps = {
    classes: {
        root: 'textField',
        focused: 'focused'
    },
};

const AssessmentTemplateQuestionV2 = (props) => {

    const [question, setQuestion] = React.useState(props.question);
    const [dependencyEditor, setDependencyEditor] = React.useState(false);

    useEffect(() => {
        if(!_.isEqual(question,props.question))
            save();
        console.log(question.dependencies?.length>0);
    }, [question]);

    const handleChange = (event) => {
        let {name, value, type} = event.target;

        if(type === "number") {
            value = parseInt(value)
        }
        else if(type === "checkbox") {
            value = !question.required
        }
        setQuestion({...question, [name]: value});

    }

    const handleUpdateAnswers = (answers) => {
        setQuestion({...question, answers: answers});
    }

    const handleDependencySelected = (questions) => {
        if(questions?.length > 0) {
            const dependencies = questions.map((q) => ({
                id: 0,
                assessmentTemplateQuestionId: q.dependencyId,
                assessmentTemplateDependentQuestionId: q.dependentQuestionId,
                assessmentTemplateAnswerId: q.dependentAnswerId
            }))
            setQuestion({...question, dependencies: dependencies});
        }
        else {
            setQuestion({...question, dependencies: []});
        }
    }

    const save = () => {
        if(!props.readOnly) {
            const originalId = question.id;
            const url = "/rest/assessment/templates/question";
            Rest.authFetch(props.user, url, {
                method: "PUT",
                body: JSON.stringify(question)
            })
                    .then(response => {
                        if(response) {
                            setQuestion(response);
                            if(props.onChange)
                                props.onChange(originalId, response);
                        }
                        else {
                            toast.error("Error saving Question");
                        }
                    }).catch(error => {
                toast.error("Error saving Question");
                console.log(error);
            });

        }
    }

    const handleMouseLeave = () => {
        if(props.question !== question) {
            save()
        }
    }

    const handleDelete = () => {
        const originalId = question.id;
        Rest.authFetch(props.user, `/rest/assessment/templates/question/${question.id}`, {
            method: "DELETE"
        })
                .then(response => {
                    if(response.status === 401 || response.status === 403) {
                        toast.error("Unauthorized user!")
                    }
                    else if(props.onDelete) {
                        props.onDelete(originalId);
                    }
                });
    }

    return (
            <div className={"d-flex justify-content-between"} onMouseLeave={handleMouseLeave}>
                <div className="my-auto mx-2">{props.index + 1}.</div>
                <div className="container-fluid agendaItem shadow-sm border">
                    <div className="row">
                        <div className="col-md-3 my-auto">
                            <TextArea
                                    autoFocus
                                    value={question.question}
                                    label={"Question"}
                                    onChange={handleChange}
                                    name={"question"}
                                    rows={9}
                                    readOnly={props.readOnly}
                            />
                        </div>
                        <div className="col-md-8">
                            <div className={"row mb-3"}>
                                <div className={"col-md-4"}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel>Question Type</InputLabel>
                                        <Select
                                                onChange={handleChange}
                                                name={"questionType"}
                                                value={question.questionType}
                                                className={"textField bg-white"}
                                                disabled={props.readOnly}
                                        >
                                            <MenuItem value={"FREE_TEXT"}>Free Text</MenuItem>
                                            <MenuItem value={"SELECTION"}>Selection</MenuItem>
                                            <MenuItem value={"MULTIPLE_SELECTION"}>Multiple Selection</MenuItem>
                                            <MenuItem value={"RANGE"}>Range</MenuItem>
                                            <MenuItem value={"NUMERIC_VALUE"}>Numeric Value</MenuItem>
                                            <MenuItem value={"DATE"}>Date</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-md-2">
                                    <TextField
                                            label="Weight"
                                            variant="outlined"
                                            name={"weight"}
                                            type="number"
                                            onChange={handleChange}
                                            value={question.weight}
                                            InputLabelProps={inputLabelProps}
                                            className="textField"
                                            inputProps={{readOnly: props.readOnly}}
                                    />
                                </div>
                                {(question.questionType === "RANGE" || question.questionType === "NUMERIC_VALUE") &&
                                 <>
                                     <div className="col-md-2">
                                         <TextField
                                                 label="Min"
                                                 type="number"
                                                 name={"min"}
                                                 onChange={handleChange}
                                                 value={question.min ? question.min : 1}
                                                 InputLabelProps={inputLabelProps}
                                                 className="textField"
                                                 variant="outlined"
                                                 inputProps={{readOnly: props.readOnly}}
                                         />
                                     </div>
                                     <div className="col-md-2">
                                         <TextField
                                                 label="Max"
                                                 type="number"
                                                 name={"max"}
                                                 onChange={handleChange}
                                                 value={question.max ? question.max : 5}
                                                 InputLabelProps={inputLabelProps}
                                                 className="textField"
                                                 variant="outlined"
                                                 inputProps={{readOnly: props.readOnly}}
                                         />
                                     </div>
                                     <div className="col-md-2">
                                         <TextField
                                                 label="Step"
                                                 type="number"
                                                 name={"steps"}
                                                 onChange={handleChange}
                                                 defaultValue={1}
                                                 InputLabelProps={inputLabelProps}
                                                 className="textField"
                                                 variant="outlined"
                                                 inputProps={{readOnly: props.readOnly}}
                                         />
                                     </div>
                                 </>
                                }
                            </div>
                            <div className={"row"}>
                                <div className={"col-md-7"}>
                                    {(question.questionType === "SELECTION" || question.questionType === "MULTIPLE_SELECTION") &&
                                     <AssessmentAnswersTable
                                             answers={question.answers}
                                             onUpdateAnswers={handleUpdateAnswers}
                                             questionId={question.id}
                                             readOnly={props.readOnly}
                                     />
                                    }
                                </div>
                                <div className={"col-md-5"}>
                                    <div className={"row"}>
                                        <div className={"col-md-12 mt-4"}>
                                            {/*<Autocomplete*/}
                                            {/*        multiple*/}
                                            {/*        name={"dependencies"}*/}
                                            {/*        options={!props.dependencyOptions ? [] : props.dependencyOptions.filter(q => q.id !== question.id)}*/}
                                            {/*        getOptionLabel={(option) => option.question}*/}
                                            
                                            {/*        getOptionSelected={(option, value) => {*/}
                                            {/*            return option?.id === value?.id || option?.question.toLowerCase() === value?.question.toLowerCase()*/}
                                            {/*        }}*/}
                                            
                                            {/*        value={question.dependencies.map((d) => ({*/}
                                            {/*            id: d.assessmentTemplateDependentQuestionId,*/}
                                            {/*            question: props.dependencyOptions.find(o => o.id === d.assessmentTemplateDependentQuestionId).question*/}
                                            {/*        }))}*/}
                                            {/*        filterSelectedOptions*/}
                                            {/*        onChange={handleDependencySelected}*/}
                                            {/*        renderInput={(params) => (*/}
                                            {/*                <TextField*/}
                                            {/*                        {...params}*/}
                                            {/*                        variant="outlined"*/}
                                            {/*                        label="Dependencies"*/}
                                            {/*                        placeholder="Search"*/}
                                            {/*                />*/}
                                            {/*        )}*/}
                                            {/*        disabled={props.readOnly}*/}
                                            {/*/>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"col-md-1 text-center"}>
                            <div className={"row mb-5"}>
                                <FormControlLabel
                                        classes={{root: 'FormControlLabel'}}
                                        label={"Required"}
                                        control={
                                            <Checkbox
                                                    classes={{root: 'Checkbox', checked: "checked"}}
                                                    name={"required"}
                                                    checked={question.required}
                                                    onChange={handleChange}
                                                    disabled={props.readOnly}
                                            />
                                        }
                                />
                            </div>
                            {!props.readOnly &&
                             <>
                                 <div className="edit row">
                                     <DeleteOutlined className={"mx-auto"} title="Delete Question"
                                                     onClick={handleDelete}/>
                                 </div>
                                 <div className="edit row">
                                     <LinkOutlined type="link" className={"mx-auto"+(question.dependencies?.length>0?" dependencies-exist":"")} title={"Dependencies"}
                                                   onClick={() => setDependencyEditor(true)}/>
                                 </div>
                             </>
                            }
                        </div>
                    </div>
                </div>
                <AssessmentTemplateQuestionDependencyEditor open={dependencyEditor}
                                                            onClose={setDependencyEditor}
                                                            question={question}
                handleDependencySelected={handleDependencySelected}/>
            </div>

    )
}

export default withUser(AssessmentTemplateQuestionV2);