import React, { useEffect, useState } from 'react';
import { withUser } from "../../context/UserContext";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import impactivo from "../../images/logoimpactivo.png";
import './Menu.css';
import { Link } from "react-router-dom";
import Icon, { SettingOutlined, DoubleLeftOutlined, TeamOutlined, DoubleRightOutlined } from "@ant-design/icons";
import { ReactComponent as AssessmentSvg } from "../../images/assessments.svg";
import { ReactComponent as StaffSvg } from "../../images/doctor-patient-online.svg";
import { ReactComponent as PatientSvg } from "../../images/patient.svg";
import Profile from "../login/Profile";

function PCMHMenu(props) {

    const [collapsed, setCollapsed] = useState(false);
    const [collapsedIcon, setCollapsedIcon] = useState();


    useEffect(() => {
        setCollapsedIcon(() => {
            if (collapsed)
                return (<DoubleRightOutlined style={{ fontSize: '1.3em', cursor: 'pointer' }} />)
            else
                return (<DoubleLeftOutlined style={{ fontSize: '1.3em', cursor: 'pointer' }} />)
        });
    }, [collapsed])

    function logout() {

    }
    return (
        <Sidebar collapsed={collapsed}>
            <div className={"sidebar-header"}>
                <div className="container">
                    <div className="row">
                        <div className="col" onClick={() => setCollapsed(!collapsed)}>
                            {collapsedIcon}
                        </div>
                    </div>
                    <div className="row nav-row">
                        <div className="col-12 branding unSelectable">
                            <img id="logoImpactivo" src={impactivo}
                                style={{ height: 'auto', width: collapsed ? '50px' : '150px' }}
                                alt="Impactivo" />
                        </div>
                    </div>
                    {props.user &&
                        <Profile logout={logout()} collapsed={collapsed} />
                    }
                </div>
            </div>
                <Menu>
                    <MenuItem icon={<TeamOutlined style={{ fontSize: '2.0em' }} />} component={<Link to={"/huddle"} />}>
                        Team Daily Huddle
                    </MenuItem>
                    <MenuItem icon={<Icon component={StaffSvg} style={{ fontSize: '2.0em' }} />} component={<Link to={"/staffsupport"} />}>
                        Staff Support
                    </MenuItem>
                    <SubMenu label="Patient Support"
                        icon={<Icon component={PatientSvg} style={{ fontSize: '2.0em' }} />}>
                        <MenuItem component={<Link to={"/patient-graph"} />}>&#8227;&nbsp;Patient Lab Results</MenuItem>
                        <MenuItem component={<Link to="patientsupport/patients" />}>&#8227;&nbsp;Patients Views</MenuItem>
                        <MenuItem component={<Link to="patientsupport/patient" />}>&#8227;&nbsp;Patient</MenuItem>
                        <MenuItem component={<Link to="patientsupport/assessment" />}>&#8227;&nbsp;Assessment</MenuItem>
                        <MenuItem component={<Link to="patientsupport/datasetadmin" />}>&#8227;&nbsp;Data Set Admin</MenuItem>
                        <MenuItem component={<Link to="patientsupport/populationset" />}>&#8227;&nbsp;Population Set</MenuItem>
                    </SubMenu>
                    <SubMenu label={"Assessments"}
                        icon={<Icon component={AssessmentSvg} style={{ fontSize: '2.0em' }} />}>
                        <MenuItem component={<Link to="assessments/templates" />}>&#8227;&nbsp;Templates</MenuItem>
                        <MenuItem component={<Link to="assessments/batches" />}>&#8227;&nbsp;Batches</MenuItem>
                        {/*<Link to={"/assessments"}/>*/}
                    </SubMenu>
                    <div className={"separator"} />
                    <SubMenu label="Admin" icon={<SettingOutlined style={{ fontSize: '2.0em' }} />}>
                        <MenuItem component={<Link to={"/admin/staff"} />}>&#8227;&nbsp;Staff</MenuItem>
                        <MenuItem component={<Link to={"/admin/teams"} />}>&#8227;&nbsp;Teams</MenuItem>
                        <MenuItem component={<Link to={"/admin/agenda"} />}>&#8227;&nbsp;Agenda</MenuItem>
                        <MenuItem component={<Link to={"/admin/users"} />}>&#8227;&nbsp;Users</MenuItem>
                        <MenuItem component={<Link to={"/admin/content"} />}>&#8227;&nbsp;Content</MenuItem>
                        <MenuItem component={<Link to={"/admin/curriculums"} />}>&#8227;&nbsp;Curriculums</MenuItem>
                        <MenuItem component={<Link to={"/admin/trainings"} />}>&#8227;&nbsp;Trainings</MenuItem>
                        <MenuItem component={<Link to={"/health-elements"} />}>&#8227;&nbsp;Health Elements</MenuItem>
                    </SubMenu>
                    
                </Menu>
            <div className={"sidebar-bottom"}>

            </div>
        </Sidebar>

    );
}

export default withUser(PCMHMenu);