import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./ProfileItem.css"
import {EditOutlined} from "@ant-design/icons";
import NumberFormat from 'react-number-format';
import Avatar from "@mui/material/Avatar";
import {flattenCommunications} from "./ProfileAdmin";
import GenericProfile from "../../../../images/profile.png";

class ProfileItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            profile: this.props.profile,
        };
        this.onEdit = this.onEdit.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.profile !== this.props.profile) {
            this.setState({
                profile: this.props.profile
            })
        }
    }

    render() {
        const profile = this.state.profile;
        const comm = flattenCommunications(profile.communications);

        return (
                <div className="profileItem container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-xl-4 name my-auto d-flex justify-content-start" title={"Edit Staff"} onClick={this.onEdit}>
                            <Avatar alt={profile.firstName} src={profile.photo ? (profile.imageType + "," + profile.photo) : GenericProfile}/>
                            <span className={"ml-2"}>{profile.fullName}</span>
                        </div>
                        <div className="col-md-6 col-xl-3 my-auto">
                            <div className="row">
                                <div className="col-md-6 col-xl-12">
                                    <div className={"fieldHeader"}>Phone</div>
                                    <NumberFormat value={comm.phone} displayType={'text'} format="(###)###-####"/>
                                </div>
                                <div className="col-md-6 col-xl-12">
                                    <div className={"fieldHeader"}>Fax</div>
                                    <NumberFormat value={comm.fax} displayType={'text'} format="(###)###-####"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-10 col-lg-11 col-xl-4 my-auto">
                            <div className="row">
                                <div className="col-md-7 col-xl-12">
                                    <div className={"fieldHeader"}>E-mail</div>
                                    {comm.email}
                                </div>
                                <div className="col-md-5 col-xl-12">
                                    <div className={"fieldHeader"}>Web Site</div>
                                    {comm.website}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-lg-1 edit my-auto" onClick={this.onEdit}>
                            <EditOutlined type="edit" title="Edit"/>
                        </div>
                    </div>
                </div>
        );
    }

    onEdit() {
        if(this.props.onEdit)
            this.props.onEdit(this.state.profile)
    }
}

ProfileItem.propTypes = {
    profile: PropTypes.object,
    onEdit: PropTypes.func
};

export default ProfileItem;