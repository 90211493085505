import React, {useContext, useEffect, useRef, useState} from 'react'
import {withUser} from "../../../context/UserContext";
import './AssessmentsHeader.css';
import DataGrid from "react-data-grid";
import {Rest} from "../../../util/rest";
import {timeStampFormatter} from "./Formatters";
import CircularProgress from "@mui/material/CircularProgress";
import {AssignmentContext} from "./AssessmentsBatches";
import {DownloadOutlined} from "@ant-design/icons";
import {getFileName} from "../../../util/responseHandlers";
import {toast} from "react-toastify";


function AssignmentGrid(props) {
    const gridRef = useRef(null);
    const [localAssignments, setLocalAssignments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [assignmentSortColumn, setAssignmentSortColumn] = useState(null);
    const [assignmentSortDirection, setAssignmentSortDirection] = useState("NONE");
    const parentMap = useContext(AssignmentContext)

    useEffect(() => {
        if(parentMap && parentMap.assignments && (parentMap.assignments.get(props.batchId) === undefined || parentMap.assignments.get(props.batchId).length === 0)) {
            async function fetchAssignments() {
                const data = await getAssignments(props.batchId);
                setLocalAssignments(data || []);
                parentMap.updateAssignments(props.batchId, data);

            }

            fetchAssignments().then()
        }
        else {
            setLocalAssignments(parentMap.assignments.get(props.batchId));
        }
    }, [props.batchId, parentMap.assignments, parentMap.updateAssignments]);

    function getAssignments(batchId) {
        setLoading(true)
        return Rest.authFetch(props.user, "/rest/assignment/batch/" + batchId)
                .then(response => {
                    if(response.status === 401 || response.status === 403)
                        toast.error("Unauthorized!")
                    else if(response.status === 500)
                        toast.error("Error loading assignments");
                    else {
                        // setLocalAssignments(response);
                        return response
                    }
                }).finally(() => setLoading(false));

    }

    function rowKeyGetter(row) {
        return row.id;
    }

    const assignmentColumns = [
        {
            key: "assignableName",
            name: "Questionnaire"
            
        },
        {
            key: "dateCreated", name: "Created on", renderCell(props) {
                return props.row.dateCreated ? timeStampFormatter.format(new Date(props.row.dateCreated)) : "";
            }, 
        },
        {key: "assignmentType", name: "Type of Assignment",},
        {key: "personName", name: "Assigned To", },
        {
            key: "downloadAssignment", name: "",  renderCell(props) {

                return (props.row?.assignmentType === "TARGETED" || props.row?.assignmentType === "ANONYMOUS_TAKEN") ?
                       <div className={"icon my-auto"} onClick={() => download(props.row.id)}><DownloadOutlined/>
                       </div> : <></>
            }
        }


    ]

    function download(assignmentId) {
        if(assignmentId) {
            setLoading(true)
            return Rest.authFetch(props.user, "/rest/assignment/" + assignmentId + "/results")
                    .then(response => {
                        if(response.status === 401 || response.status === 403)
                            toast.error("Unauthorized!")
                        else if(response.status === 500)
                            toast.error("Error generating download File");
                        else {
                            response.text().then(v => {
                                const url = window.URL.createObjectURL(new Blob([v]))
                                const link = document.createElement('a')
                                link.href = url
                                link.setAttribute('download', getFileName(response))
                                document.body.appendChild(link)
                                link.click()
                                link.remove()
                            })
                        }
                    }).finally(() => setLoading(false));
        }
    }

    return (
            <>
                {loading && <div className={"wait"}><CircularProgress/></div>}
                <DataGrid
                        className="rdg-light"
                        ref={gridRef}
                        rows={localAssignments}
                        columns={assignmentColumns}
                        rowKeyGetter={rowKeyGetter}
                        style={{blockSize: 250}}
                        direction={props.direction}
                />
            </>
    );
}

export default withUser(AssignmentGrid);