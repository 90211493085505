import React, {Component} from 'react';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import './Selection.css'

class Selection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            templateAnswers: [...props.question.answers],
            answers: props.answers,
            readOnly: props.readOnly
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(JSON.stringify(prevProps.answers) !== JSON.stringify(this.props.answers))
            this.setState({answers: this.props.answers})
        if(prevProps.readOnly !== this.props.readOnly)
            this.setState({readOnly: this.props.readOnly})
    }

    handleChange(event) {
        if(!this.state.readOnly) {
            const templateAnswer = this.state.templateAnswers.find(item => item.id === parseInt(event.target.id));

            let answer = this.state.answers && this.state.answers.length > 0 ? this.state.answers[0] : {
                questionId: this.props.question.id
            };
            answer.assessmentTemplateAnswerId = parseInt(event.target.id);
            answer.answerText = templateAnswer.hasFreeText ? answer.answerText : templateAnswer.answer;
            this.setState({answers: [answer]});
            if(this.props.handleNewAnswer)
                this.props.handleNewAnswer([answer]);
        }
    }

    saveAnswers() {
        if(!this.state.readOnly && this.props.handleNewAnswer)
            this.props.handleNewAnswer(this.state.answers);
    }

    handleKeyUp(e, index) {
        if(index > -1) {
            const answers = this.state.answers;
            answers[index].answerText = e.target.value;
            this.setState({answers: answers})
        }
    }

    render() {
        return (
                <div className={"selection"}>
                    {this.state.templateAnswers.map((templateAnswer, index) => {
                        const answerIndex = this.state.answers.findIndex(a => a.assessmentTemplateAnswerId === templateAnswer.id);
                        const checked = this.state.answers.findIndex(a => a.assessmentTemplateAnswerId === templateAnswer.id) > -1;
                        const rootClass = this.state.readOnly ? 'Radio read-only' : 'Radio';
                        return (
                                <div className="answer mx-3" key={index.toString()}>

                                    <FormControlLabel
                                            classes={{root: 'FormControlLabel'}}
                                            control={
                                                <Radio
                                                        classes={{root: rootClass, checked: 'checked'}}
                                                        id={templateAnswer.id.toString()}
                                                        value={templateAnswer.answer}
                                                        checked={checked}
                                                        onChange={this.handleChange}
                                                        key={index}
                                                />
                                            }
                                            label={templateAnswer.answer}
                                    />
                                    {(answerIndex > -1 && templateAnswer.hasFreeText) ?
                                     <span>
                                 {this.state.readOnly ? answerIndex > -1 ? this.state.answers[answerIndex].answerText : ""
                                                      : <input
                                          onKeyUp={(e) => this.handleKeyUp(e, answerIndex)}
                                          defaultValue={answerIndex > -1 ? this.state.answers[answerIndex].answerText : ""}
                                          onBlur={() => this.saveAnswers()}
                                  />}
                                 </span> : null}
                                </div>
                        )
                    })}
                </div>
        )
    }
}

export default Selection;