import React, {Component} from 'react';
import * as InputTypes from "./inputtypes";
import {Rest} from "../../../util/rest";
import toast from "../../../util/toast";
import {withUser} from "../../../context/UserContext";
import './Assessment.css'

class AssessmentQuestion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: props.index,
            question: props.question,
            error: null,
            readOnly: props.readOnly,
            assessmentId: props.assessmentId,
            answers: [],
            valid: true,
            submitted: props.submitted
        }
        this.handleNewAnswer = this.handleNewAnswer.bind(this);
    }

    componentDidMount() {
        this.getAnswers();
    }

    capitalizeWords(value) {
        let string = value.toLowerCase().split("_");
        string = string.map((word) => word[0].toUpperCase() + word.slice(1))
        return string.join("");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.error !== this.props.error)
            this.setState({error: this.props.error})
        if(prevProps.readOnly !== this.props.readOnly)
            this.setState({readOnly: this.props.readOnly})
        if(prevProps.valid !== this.props.valid) {
            this.setState({valid: this.props.valid})
        }
    }

    handleNewAnswer(answers) {
        answers.forEach((answer) => {
            answer.assessmentId = this.state.assessmentId;
            answer.questionId = this.state.question.id;
        })
        this.saveAnswer(answers);
        if(this.props.onAnswer)
            this.props.onAnswer(this.state.question, answers);
    }

    getAnswers() {
        Rest.authFetch(this.props.user, "/rest/assessment/" + this.state.assessmentId + "/" + this.state.question.id + "/answers")
                .then(response => {
                    if(response.status === 403) {
                        toast.error("Error saving Answers. Unauthorized user.");
                    }
                    else if(response) {
                        this.setState({answers: response})
                        this.handleNewAnswer(response);
                    }
                    else {
                        toast.error("Error saving Answers");
                    }
                });
    }

    saveAnswer(answers) {
        Rest.authFetch(this.props.user, "/rest/assessment/" + this.state.assessmentId + "/" + this.state.question.id + "/answers", {
            method: "PUT",
            body: JSON.stringify(answers)
        })
                .then(response => {
                    if(response.status === 403) {
                        toast.error("Error saving Answers. Unauthorized user.");

                    }
                    else if(response) {
                        this.setState({answers: response});
                    }
                    else {
                        toast.error("Error saving Answers");
                    }
                });
    }

    render() {
        // QuestionTypes: FREE_TEXT, SELECTION, MULTIPLE_SELECTION, RANGE, NUMERIC_VALUE, DATE
        const qType = this.capitalizeWords(this.state.question.questionType);
        const QuestionType = InputTypes[qType]
        const error = this.state.question.error;
        return (
                <div className={"flex-fill question p-3 mb-3" + (!this.state.valid ? " question-error" : "") + (this.state.readOnly ? " read-only" : "")}>
                    {error ?
                     <div className="question-error row">
                         <div className="col-md-12">
                             {error}
                         </div>
                     </div> : null
                    }
                    <div className="row">
                        <div className={"col-md-12"}>
                            <div className={"row"}>
                                <div className="col-md-12 align-items-center mb-4 font-weight-bold">
                                    <span>{this.state.question.question}</span>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="col-md-12">
                                    <QuestionType handleNewAnswer={this.handleNewAnswer}
                                                  question={this.state.question}
                                                  answers={this.state.answers}
                                                  readOnly={this.state.readOnly}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }


}

export default withUser(AssessmentQuestion);