import React, {useContext, useEffect} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ListSubheader,
    MenuItem,
    Select
} from "@mui/material";
import {TemplateContext} from "./AssessmentTemplateEditorV2";
import './AssessmentTemplateEditorV2.css';

const AssessmentTemplateQuestionDependencyEditor = (props) => {
    const template = useContext(TemplateContext);
    const [questions, setQuestions] = React.useState([]);
    const [selectedDependencyQuestionId, setSelectedDependencyQuestionId] = React.useState("");
    const [selectedAnswerId, setSelectedAnswerId] = React.useState("");
    const [answers, setAnswers] = React.useState([]);
    const [dependencyId, setDependencyId] = React.useState(0);
    const isValidQuestion = (q) => q.id !== props.question.id && q.questionType === "SELECTION";

    useEffect(() => {
        if(props.question.dependencies?.length > 0) {
            setDependencyId(props.question.dependencies[0].id);
            setSelectedDependencyQuestionId(props.question.dependencies[0].assessmentTemplateDependentQuestionId);
            setSelectedAnswerId(props.question.dependencies[0].assessmentTemplateAnswerId);
        }
        else {
            setDependencyId(0);
            setSelectedDependencyQuestionId("");
            setSelectedAnswerId("");

        }
        console.log(props.question);
    }, [props.question, props.open]);

    useEffect(() => {
        if(template) generateFlattenQuestions(template.groups);
    }, [template]);

    useEffect(() => {
        const question = questions.find((question) => question.id === selectedDependencyQuestionId);
        if(question?.answers) {
            setAnswers(question.answers);
        }

    }, [selectedDependencyQuestionId]);

    const generateFlattenQuestions = (groups) => {
        if(!groups || groups.length === 0) setQuestions([]);
        const localQuestions = [];
        let lastGroup = null;
        groups.forEach(group => {
            if(lastGroup !== group.name) {
                lastGroup = group.name;
                localQuestions.push({
                                        id: group.id,
                                        group: group.name,
                                        groupOrder: group.order,
                                        groupId: group.id,
                                        isGroup: true
                                    });
            }
            group.questions?.filter(isValidQuestion).forEach(q => {
                localQuestions.push(
                        {
                            id: q.id,
                            order: q.order,
                            groupOrder: group.order,
                            question: q.question,
                            questionType: q.questionType,
                            group: group.name,
                            groupId: group.id,
                            answers: q.answers,
                            isGroup: false
                        });
            })

        });
        setQuestions(localQuestions);
    }

    const saveDependencies = (remove) => {
        if(remove) {
            props.handleDependencySelected([]);
            setSelectedAnswerId("");
            setSelectedDependencyQuestionId("");
            setDependencyId(0);

        }
        else
            props.handleDependencySelected([{
                dependencyId: dependencyId,
                dependentQuestionId: parseInt(selectedDependencyQuestionId),
                dependentAnswerId: parseInt(selectedAnswerId)
            }])
    }

    return (
            <Dialog open={props.open}>
                <DialogTitle title={props.title} classes={{root: "editorHeader"}}>
                    Dependency Editor
                    <div className="current-question">
                        {props.question.question}
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="dependency-editor-contents">
                        <div className="prompt">
                            Show this question if the following question:
                        </div>
                        <div className="list-of-questions" style={{width:'100%'}}>

                            <Select onChange={(e) => setSelectedDependencyQuestionId(e.target.value)}
                                    
                                    className="dependency-editor-select"
                                    value={selectedDependencyQuestionId}>
                                <MenuItem value="" disabled>
                                    Select a question...
                                </MenuItem>
                                {questions.map((option) => {
                                    if(option.isGroup) {
                                        return (
                                                <ListSubheader key={option.groupId}
                                                               sx={{
                                                                   fontSize: "1.3em",
                                                                   color: "var(--smartpcmh-text-color)",
                                                               }}
                                                >
                                                    {option.group}
                                                </ListSubheader>);
                                    }
                                    else {
                                        return (<MenuItem
                                                // sx={{
                                                //     marginLeft: "1em",
                                                //     whiteSpace: "nowrap",
                                                //     overflow: "hidden",
                                                //     textOverflow: "ellipsis",
                                                //     display: "block",
                                                // }}
                                                key={option.id}
                                                value={option.id}
                                        >
                                            {option.order + ". " + option.question}
                                        </MenuItem>);
                                    }
                                })};
                            </Select>
                        </div>
                        <div className="prompt">
                            Has the following answer:
                        </div>
                        <div>
                            <Select
                                    className="dependency-editor-select"
                                    value={selectedAnswerId}
                                    onChange={(e) => setSelectedAnswerId(e.target.value)}>
                                <MenuItem value="" disabled key={-1}>
                                    Select an answer...
                                </MenuItem>
                                {answers && answers.map((answer) => {
                                    return <MenuItem
                                            sx={{
                                                marginLeft: "1em",
                                                whiteSpace: "nowrap", // Prevent text from wrapping
                                                overflow: "hidden",   // Hide overflowing content
                                                textOverflow: "ellipsis", // Show ellipsis for long text
                                                maxWidth: "100%",    // Restrict width to container
                                                display: "block",
                                            }}

                                            value={answer.id} key={answer.id}>{answer.answer}</MenuItem>
                                })}
                            </Select>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className="dependency-buttons">
                    <Button onClick={() => props.onClose(false)} variant="outlined">Cancel</Button>
                    <Button onClick={() => {
                        saveDependencies(true);
                        props.onClose(false)
                    }} variant="outlined"
                            disabled={selectedDependencyQuestionId === "" || selectedAnswerId === ""}>Remove
                        Dependency</Button>
                    <Button onClick={() => {
                        saveDependencies(false);
                        props.onClose(false)
                    }} variant="outlined"
                            disabled={selectedDependencyQuestionId === "" || selectedAnswerId === ""}>Save</Button>
                </DialogActions>
            </Dialog>
    )
}
export default AssessmentTemplateQuestionDependencyEditor;