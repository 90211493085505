import React, {useState} from "react";
import {withUser} from "../../../../context/UserContext";
import Icon, {CopyOutlined, DeleteOutlined, EditOutlined} from "@ant-design/icons";
import AssessmentBatchAssign from "../assessments/AssessmentBatchAssign";

const GroupAssignSVG = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="45px" height="40px" viewBox="0 0 45 40">
            <defs/>
            <g fill="none" stroke="#535353">
                <path d="M36.983 88.53s13.092 9.458 39.276 28.49l49.728-71.028"
                      transform="matrix(.06803 0 0 .06897 28.844 0)" strokeWidth="25"/>
                <path d="M156.018 81.506c0 41.121-33.362 74.482-74.533 74.482-41.114 0-74.476-33.361-74.476-74.482 0-41.178 33.362-74.483 74.476-74.483 41.171 0 74.533 33.305 74.533 74.483zm0 0"
                      transform="matrix(.06803 0 0 .06897 28.844 0)" strokeWidth="25"/>
                <path d="M373.635 424.646s32.156 14.274 83.778 14.274c34.281 0 62.475-4.758 84.64-14.274C561.577 329.434 533.325 281.8 457.3 281.8c-113.983 0-83.664 142.847-83.664 142.847zm0 0"
                      transform="matrix(.06803 0 0 .06897 .012 3.241)" strokeWidth="30" strokeLinejoin="round"/>
                <path d="M182.65 416.66s32.156 14.274 83.72 14.274c34.282 0 62.533-4.758 84.698-14.274 19.524-95.27-8.728-142.848-84.755-142.848-113.982 0-83.663 142.848-83.663 142.848zm0 0"
                      transform="matrix(.06803 0 0 .06897 .012 3.241)" strokeWidth="30" strokeLinejoin="round"/>
                <path d="M240.818 63.506c0 31.209-25.323 56.47-56.503 56.47s-56.503-25.261-56.503-56.47c0-31.21 25.323-56.527 56.503-56.527s56.503 25.318 56.503 56.527zm0 0M425.831 63.506c0 31.209-25.323 56.47-56.503 56.47-31.237 0-56.503-25.261-56.503-56.47 0-31.21 25.266-56.527 56.503-56.527 31.18 0 56.503 25.318 56.503 56.527zm0 0M367.318 128.813c18.663 0 34.339 2.945 46.857 9.062 12 5.89 21.016 14.727 27.103 26.451.861 1.586 1.608 3.229 2.297 4.871 1.722-.453 3.445-.906 5.225-1.246a58.46 58.46 0 0110.508-.963c15.62 0 29.745 6.344 39.966 16.54 10.221 10.251 16.537 24.355 16.537 39.988a56.275 56.275 0 01-16.537 39.931c-10.221 10.252-24.347 16.54-39.966 16.54-12.805 0-24.634-4.249-34.108-11.442-.804-.623-1.608-1.303-2.412-1.926-1.091.227-2.125.453-3.216.623-15.389 3.172-32.788 4.701-52.196 4.701-28.596 0-51.048-4.53-65.06-8.382a204.39 204.39 0 00-2.985-.85 22.244 22.244 0 01-2.47 1.53A56.17 56.17 0 01268.324 272c-8.613 0-16.767-1.926-24.06-5.38-.689-.34-1.435-.68-2.124-1.02-.804.17-1.608.396-2.412.566-16.652 3.852-35.774 5.777-57.364 5.777-7.867 0-15.332-.34-22.28-.906l-16.538-1.416 10.566 12.8c17.973 21.75 21.304 57.378 11.829 106.202-20.902 8.27-47.086 12.291-78.553 12.291-40.253 0-68.332-8.893-77.577-12.348 0-.113 0-.226-.058-.396l-.114-.793c-2.297-14.104-7.35-55.904 7.58-88.643 8.67-19.03 24.346-34.834 51.966-39.535l26.472-4.474-25.323-9.006a56.669 56.669 0 01-27.218-20.73c-6.49-9.177-10.279-20.391-10.279-32.512 0-15.577 6.316-29.68 16.538-39.932 10.22-10.195 24.346-16.54 39.965-16.54 11.886 0 22.911 3.683 32.042 9.97 1.55 1.076 2.986 2.209 4.421 3.398a52.564 52.564 0 014.134-3.852c11.944-10.308 28.884-16.708 52.37-16.708 32.557 0 55.928 8.949 69.48 28.15.918 1.246 1.722 2.492 2.526 3.795 1.436-.397 2.929-.68 4.422-.963 3.1-.51 6.316-.793 9.59-.793a56.476 56.476 0 0124.116 5.38 57.418 57.418 0 015.8 3.173c.976-1.983 2.01-3.965 3.158-5.89 11.485-19.032 31.697-32.852 65.92-32.852zm0 0"
                      transform="matrix(.06803 0 0 .06897 .012 3.241)" strokeWidth="30"/>
                <path d="M87.33 257.783c34.74 0 59.144 10.309 72.179 32.285 13.092 22.147 14.872 55.055 6.431 98.555-20.902 8.27-47.086 12.291-78.553 12.291-40.253 0-68.332-8.95-77.577-12.348-2.354-13.65-8.958-62.757 11.197-97.195 11.37-19.428 31.64-33.588 66.322-33.588zm0 0M145.842 192.477c0 31.209-25.323 56.527-56.503 56.527-31.237 0-56.503-25.318-56.503-56.527 0-31.153 25.266-56.471 56.503-56.471 31.18 0 56.503 25.318 56.503 56.47zm0 0M324.826 215.473c0 31.209-25.323 56.527-56.503 56.527s-56.503-25.318-56.503-56.527c0-31.153 25.323-56.471 56.503-56.471s56.503 25.318 56.503 56.47zm0 0M515.811 223.516c0 31.209-25.265 56.47-56.503 56.47-31.18 0-56.503-25.261-56.503-56.47 0-31.21 25.323-56.528 56.503-56.528 31.238 0 56.503 25.319 56.503 56.528zm0 0"
                      transform="matrix(.06803 0 0 .06897 .012 3.241)" strokeWidth="30"/>
            </g>
        </svg>

);
const GroupAssignOutlined = props => <Icon component={GroupAssignSVG} {...props} />;


const AssessmentTemplateV2 = (props) => {
    const [batchAssign, setBatchAssign] = useState(false);
    
    return (
            <div className={`assessment-template ${props.template.status === "PUBLISHED" ? "published" : ""} ${props.template.status === "INACTIVE" ? "inactive" : ""} container-fluid `}>
                <div className="row">
                    <div className="col-md-4 col-lg-7 col-xl-8 name my-auto">
                        {props.template.name}
                    </div>
                    <div className={"col-md-8 col-lg-5 col-xl-4"}>
                        <div className={"row"}>
                            <div className="col-3 my-auto unSelectable">
                                    <span className="questions"
                                          title="Questions">{props.template.questionsQty}</span>
                            </div>
                            <div className="col-2  edit my-auto unSelectable"
                                 onClick={() =>props.onEdit(props.template)}
                                 title="Edit">
                                <EditOutlined title="Edit"/>
                            </div>
                            <div className="col-2 edit my-auto unSelectable"
                                 onClick={() =>props.onClone(props.template.id)}>
                                <CopyOutlined title="Clone"/>
                            </div>
                            {!props.readOnly &&
                             <div className="col-2 edit my-auto unSelectable"
                                  onClick={() => props.onDelete(props.template)}>
                                 <DeleteOutlined title="Delete"/>
                             </div>
                            }
                            {props.template && props.template.status === "PUBLISHED" &&
                             <div className="col-2 edit my-auto unSelectable"
                                  onClick={() =>setBatchAssign(true)}>
                                 <GroupAssignOutlined title="Batch Assign"/>
                             </div>
                            }
                        </div>
                    </div>
                </div>
                <AssessmentBatchAssign open={batchAssign} onClose={()=>setBatchAssign(false)}
                                       template={props.template}/>
            </div>

    )
}

export default withUser(AssessmentTemplateV2); 