import React, {Component} from 'react';
import {withUser} from "../../../context/UserContext";


class TeamHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            teams: props.teams
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.teams !== this.props.teams)
            this.setState({teams: this.props.teams})

    }

    render() {
        const team = this.state.teams && this.state.teams.length > 0 ? this.state.teams[0] : "Not a member of a team";
        return (
                <div className="pcmh-content-header">
                    <h2>{team.name}</h2>
                </div>
        )
    }
    
   
}

export default withUser(TeamHeader);