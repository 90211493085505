import React, {createContext, useEffect, useState} from "react";
import {withUser} from "../../../../context/UserContext";
import {CloseOutlined, EditOutlined, ExportOutlined, PlusOutlined, StopOutlined} from "@ant-design/icons";
import Button from "@mui/material/Button";
import TextArea from "../../assessment/inputtypes/TextArea";
import {DragDropContext} from "react-beautiful-dnd";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {Rest} from "../../../../util/rest";
import toast from "../../../../util/toast";
import AssessmentTemplateGroupV2 from "./AssessmentTemplateGroupV2";
import './AssessmentTemplateEditorV2.css'

export const TemplateContext = createContext(null);

const AssessmentTemplateEditorV2 = (props) => {

    const [template, setTemplate] = useState(props.template);
    const [lightTemplate, setLightTemplate] = useState(null)
    const [editMode, setEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [questionQty, setQuestionQty] = useState(0);
    const [save, setSave] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showGroups, setShowGroups] = useState(true);

    useEffect(() => {
        if(template) {
            if(template.id === 0) {
                simpleSave();
            }
            else {
                getTemplate();
            }
        }
    }, []);

    useEffect(() => {
        const lightTemplate = {...template};
        delete lightTemplate.groups;
        setLightTemplate(lightTemplate);
        setQuestionQty(!template || !template.groups || template.groups.length === 0 ? 0 : template.groups.map((group) => group.questions).reduce((questions, q) => questions.concat(q)).length);
        if(props.onTemplateUpdated)
            props.onTemplateUpdated({
                                        ...lightTemplate,
                                        questionsQty: questionQty,
                                    });

    }, [template])

    useEffect(() => {
        if(save) {
            simpleSave();
            setSave(false);
        }
    }, [save])


    function addGroup() {
        const newGroup = {
            id: 0,
            templateId: template.id,
            name: "New Group",
            description: "",
            instructions: "",
            order: template.groups.length + 1,
            questions: [],
            weight: 0,
            displayInfo: false
        }
        const groups = template.groups;
        groups.push(newGroup);
        setTemplate({...template, groups: groups});
    }

    function cancel() {
        if(props.onEditorClose)
            props.onEditorClose();
    }

    function getTemplate() {
        setIsLoading(true);
        Rest.authFetch(props.user, `/rest/assessment/templates/${template.id}`).then(response => {
            if(response.status === 401 || response.status === 403) {
                toast.error("Unauthorized!")
            }
            else {
                setTemplate(response);
                setIsLoading(false);
                setIsSaving(false);
            }
        })
                .catch(err => {
                    console.log(err);
                    setIsLoading(false);
                })
    }

    function saveAssessmentTemplate() {
        setIsSaving(true);
        Rest.authFetch(props.user, "/rest/assessment/templates", {
            method: "PUT",
            body: JSON.stringify(template)
        })
                .then(response => {
                    if(response) {
                        setTemplate(response);
                        setIsSaving(false);
                        setIsLoading(false);
                    }
                    else {
                        toast.error("Error saving Template");
                    }
                });
    }

    function simpleSave() {
        setIsSaving(true);
        const groups = template.groups;
        Rest.authFetch(props.user, "/rest/assessment/templates/simple", {
            method: "PUT",
            body: JSON.stringify(template)
        })
                .then(response => {
                    if(response) {
                        setTemplate({...response, groups: groups});
                        setIsSaving(false);
                        setIsLoading(false);
                        setEditMode(false)
                    }
                    else {
                        toast.error("Error saving Template");
                    }
                });
    }

    function onGroupChange(originalId, group) {
        const groups = template.groups;
        const groupIndex = groups.findIndex(g => g.id === originalId);
        if(groupIndex >= 0) {
            groups[groupIndex] = group;
            setTemplate({...template, groups: groups});
        }
    }

    function onGroupDelete(groupId) {
        const groups = template.groups;
        const groupIndex = groups.findIndex(q => q.id === groupId);
        if(groupIndex >= 0) {
            groups.splice(groupIndex, 1);
            setTemplate({...template, groups: groups})
        }
    }

    function onDragEnd(result) {
        if(!props.readOnly) {
            const {source, destination} = result;
            if(!destination) {
                return;
            }
            const groups = template.groups;
            if(source.droppableId === destination.droppableId) {
                const groupIndex = groups.findIndex(g => {return g.id === parseInt(destination.droppableId)});
                const group = groups[groupIndex];
                group.questions = reorder(
                        group.questions,
                        result.source.index,
                        result.destination.index
                );
                groups[groupIndex] = group;
            }
            else {

                const result = move(
                        getGroup(source.droppableId),
                        getGroup(destination.droppableId),
                        source,
                        destination
                );


                groups[getGroupIndex(source.droppableId)] = result.sourceGroup;
                groups[getGroupIndex(destination.droppableId)] = result.destinationGroup;
            }
            setTemplate({...template, groups: groups});
            saveAssessmentTemplate();
        }
    }

    function reorder(list, startIndex, endIndex) {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        result.map((question, index) => question.order = index + 1);
        return result;
    }

    function move(source, destination, droppableSource, droppableDestination) {
        const sourceClone = Array.from(source.questions);
        const destClone = Array.from(destination.questions);
        const [removed] = sourceClone.splice(droppableSource.index, 1);
        destClone.splice(droppableDestination.index, 0, removed);
        sourceClone.map((question) => question.groupId = source.id);
        destClone.map((question) => question.groupId = destination.id);
        source.questions = sourceClone;
        destination.questions = destClone
        return {sourceGroup: source, destinationGroup: destination};
    }

    function getGroup(groupId) {
        return template.groups.find(g => {return g.id === parseInt(groupId)});
    }

    function getGroupIndex(groupId) {
        return template.groups.findIndex(g => {return g.id === parseInt(groupId)});
    }

    function changeStatus() {
        if(template.status === "DRAFT" || template.status === "PUBLISHED") {
            const newStatus = template.status === "DRAFT" ? "PUBLISHED" : "INACTIVE";
            setTemplate({...template, status: newStatus});
            setLightTemplate({...lightTemplate, status: newStatus})
            setSave(true);
            setShowAlert(false);
        }
    }

    return (
            <TemplateContext.Provider value={template}>
                <div className="template-editor">
                    <div className="pcmh-content-header container-fluid">
                        <div className="row">
                            {editMode ?
                             <div className="col-md-8 name my-auto">
                                 <input type="text"
                                        autoFocus={true}
                                        defaultValue={template.name}
                                        className="editing"
                                        onChange={(e) => setTemplate({...template, name: e.target.value})}
                                        onBlur={() => {simpleSave()}}
                                        onFocus={(e) => e.target.select()}
                                 />
                             </div>
                                      :
                             <div className="col-md-8 name my-auto" style={{cursor: 'pointer'}}
                                  onClick={() => setEditMode(!props.readOnly)}>
                                 {template.name}
                             </div>
                            }
                            <div className={"col-4 text-end my-auto"}>
                                {!props.readOnly &&
                                 <Button variant="outlined"
                                         title="Edit"
                                         size="small"
                                         style={{marginRight: "3px"}}

                                         onClick={() => setEditMode(true)}
                                         endIcon={<EditOutlined/>}>
                                     Edit
                                 </Button>
                                }
                                <Button variant="outlined"
                                        title="Exit" size="small"
                                        onClick={() => {cancel()}}
                                        style={{marginRight: "3px"}}
                                        endIcon={<CloseOutlined/>}>
                                    Exit
                                </Button>

                                <Button variant="outlined"
                                        size="small"
                                        style={{cursor: template.status === "INACTIVE" ? "not-allowed" : "pointer"}}
                                        endIcon={template.status === "DRAFT" ? <ExportOutlined/> : <StopOutlined/>}
                                        onClick={() => {
                                            if(template.status !== "INACTIVE") setShowAlert(true)
                                        }}>
                                    {template.status === "DRAFT" ? "Publish" : template.status === "PUBLISHED" ? "Deactivate" : "Inactive"}
                                </Button>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <TextArea
                                    autofocus={false}
                                    label={"Assessment Description"}
                                    name={"description"}
                                    defaultValue={template.description}
                                    onChange={(e) => setTemplate({...template, description: e.target.value})}
                                    onBlur={() => { simpleSave() }}
                                    readOnly={props.readOnly}
                            />
                        </div>
                        <div className="col-md-6">
                            <TextArea
                                    autofocus={false}
                                    label={"Assessment Instructions"}
                                    name={"instructions"}
                                    defaultValue={template.instructions}
                                    onChange={(e) => setTemplate({...template, instructions: e.target.value})}
                                    onBlur={() => {simpleSave() }}
                                    readOnly={props.readOnly}
                            />
                        </div>
                    </div>
                    <div className="row mt-4" style={{borderTop: "solid 1px"}}>
                        <div className="col-8">
                        <span className="group-control-label"
                        >Groups</span>
                        </div>
                        <div className="col-4 text-end my-auto">
                            {!props.readOnly &&
                             <Button variant="outlined"
                                     size="small"
                                     endIcon={<PlusOutlined className="mx-auto icon"/>}
                                     onClick={() => addGroup()}>
                                 Add Group
                             </Button>
                            }
                        </div>
                    </div>
                    <div className="template-content-parent">
                        <div className={"template-content"}>
                            {isLoading &&
                             <div className="text-center my-5">
                                 <button className="btn btn-primary-color btn-lg rounded shadow-sm" type="button"
                                         disabled>
                                     <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"/>
                                     Loading...
                                 </button>
                             </div>
                            }
                            {isSaving && <div className="text-center my-5 saving-notification">
                                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"/>
                                Saving...
                            </div>}
                            <DragDropContext onDragEnd={onDragEnd}>
                                {template && template.groups && template.groups.sort((a, b) => (a.order > b.order) ? 1 : -1).map((group, index) => (
                                        <AssessmentTemplateGroupV2 key={group.id} group={group} index={index}
                                                                   onStartSave={() => {setIsSaving(true)}}
                                                                   onEndSave={() => {setIsSaving(false)}}
                                                                   onGroupChange={onGroupChange}
                                                                   template={lightTemplate}
                                                                   onDelete={onGroupDelete}
                                                                   readOnly={props.readOnly}
                                                                   collapsed={!showGroups}
                                        />
                                ))}
                            </DragDropContext>
                        </div>
                    </div>
                    <div>
                        <Dialog open={showAlert} aria-labelledby="form-dialog-title"
                                onClose={(event, reason) => {
                                    if(reason !== 'backdropClick' && reason !== 'escapeKeyDown')
                                        setShowAlert(false);
                                }}
                                className="pcmh-content-uploader">
                            <DialogTitle title="Status Change" classes={{root: "editorHeader"}}>Change
                                Status</DialogTitle>
                            <DialogContent>
                                <span className={"warning-text unSelectable"}>Warning! Changing state is not reversible</span>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => {setShowAlert(false)}} variant="contained"
                                        className="editor-button">Cancel</Button>
                                <Button onClick={() => {changeStatus();}} variant="contained"
                                        className="editor-button">Confirm</Button>
                            </DialogActions>
                        </Dialog>
                    </div>

                </div>
            </TemplateContext.Provider>
    );
}

export default withUser(AssessmentTemplateEditorV2)