import React, {Component} from 'react';
import {LeftOutlined, RightOutlined, VerticalLeftOutlined, VerticalRightOutlined} from "@ant-design/icons";
import './FileViewer.css'

class PageSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            lastPage: props.lastPage,
            editingCurrentPage: false
        };
        this.valueChanged = this.valueChanged.bind(this);
        PageSelector.handleFocus = PageSelector.handleFocus.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.currentPage !== this.props.currentPage)
            this.setState({currentPage: this.props.currentPage});
        if(prevProps.lastPage !== this.props.lastPage)
            this.setState({lastPage: this.props.lastPage});

    }

    render() {
        const currentPage = this.state.currentPage ? this.state.currentPage : 0;
        const lastPage = this.state.lastPage ? this.state.lastPage : 0;
        const editing = this.state.editingCurrentPage;
        return (
                <div className="page-control">
                    <VerticalRightOutlined name="firstPage" id="firstPage" className="page-button"
                                           title="First Page"
                                           onClick={() => this.pageMoved('firstPage')}/>
                    <LeftOutlined name="previousPage" id="previousPage" className="page-button"
                                  title="Previous Page"
                                  onClick={() => this.pageMoved('previousPage')}/>
                    <div className="page-number"
                         onClick={() => this.editMode(true)}>{editing ?

                                                              <input type="number" id="getPage" name="getPage"
                                                                     defaultValue={currentPage}
                                                                     onBlur={() => this.editMode(false)}
                                                                     onKeyUp={this.valueChanged}
                                                                     onFocus={PageSelector.handleFocus}
                                                                     ref={(input) => { this.pageInput = input; }}/> :
                                                              currentPage}</div>
                    <div className="of">of</div>
                    <div className="page-number">{lastPage}</div>
                    <RightOutlined name="nextPage" id="nextPage" className="page-button" title="Next Page"
                                   onClick={() => this.pageMoved('nextPage')}/>
                    <VerticalLeftOutlined name="lastPage" id="lastPage" className="page-button" title="Last Page"
                                          onClick={() => this.pageMoved('lastPage')}/>
                </div>
        );
    }

    valueChanged(event) {
        const target = event.target;
        const value = target.value;
        if((event.keyCode === 13 || event.charCode === 13)) {
            if(value > 0 && value <= this.state.lastPage) {
                this.setPage(value);
            }
            this.editMode(false);
            return;
        }

        this.setState((state) => ({
            document: {
                ...state.document,
                editValue: value
            }
        }));

    }

    pageMoved(e) {
        let currentPage = this.state.currentPage;
        const lastPage = this.state.lastPage;
        let pageChanged = false;
        switch(e) {
            case "firstPage":
                currentPage = 1;
                pageChanged = true;
                break;
            case "previousPage":
                if(currentPage > 1) {
                    currentPage--;
                    pageChanged = true;
                }
                break;
            case "nextPage":
                if(currentPage < lastPage) {
                    currentPage++;
                    pageChanged = true;
                }
                break;
            case "lastPage":
                currentPage = lastPage;
                pageChanged = true;
                break;
            default:
        }

        if(pageChanged) {
            this.setPage(currentPage);
        }
    }

    setPage(newValue) {
        this.setState({currentPage: newValue});
        if(this.props.onPageChanged) {
            this.props.onPageChanged(newValue);
        }

    }

    editMode(mode) {
        this.setState({editingCurrentPage: mode}, () => {
            if(mode && this.pageInput)
                this.pageInput.focus();
        });
    }

    static handleFocus(event) {
        event.target.select();
    }
}

export default PageSelector;