import React, {Component} from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {withUser} from "../../../context/UserContext";
import Rater from "./Rater";

class Rate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            document: props.document,
            show: props.show,
            rating: 0,
            comments: ""

        };
        this.handleSave = this.handleSave.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.rateChanged = this.rateChanged.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.show !== this.props.show) {
            this.setState({show: this.props.show});
        }

    }

    render() {
        const rating = this.state.rating;
        return (
                <div>
                    <Dialog open={this.state.show} aria-labelledby="form-dialog-title"
                            // onClose={(event,reason)=>{
                            //     if(reason !== 'backdropClick' && reason !== 'escapeKeyDown')
                            //         setShowAlert(false);
                            // }}
                            disableBackdropClick
                            disableEscapeKeyDown
                    >

                        <DialogTitle classes={{root: "editorHeader"}} id="form-dialog-title">Rate Document</DialogTitle>
                        <DialogContent className="container-fluid pcmh-profile-editor">
                            <Rater onChange={this.rateChanged} rating={rating}/>
                        </DialogContent>
                        <div className="editorFooter">
                            <DialogActions>
                                <Button onClick={this.handleCancel} variant="contained" className="editorButton">
                                    Cancel
                                </Button>
                                <Button onClick={this.handleSave} variant="contained" className="editorButton">
                                    Save
                                </Button>
                            </DialogActions>
                        </div>

                    </Dialog>
                </div>
        );
    }

    handleSave() {

        this.handleCancel()
    }

    handleCancel() {
        if(this.props.onRatingComplete)
            this.props.onRatingComplete();
        this.setState({show: false})
    }

    rateChanged(value) {
        this.setState({rating: value});
    }
}

export default withUser(Rate);