import React, {Component} from 'react';
import PdfJsLib from "pdfjs-dist";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";


class PDFViewer extends Component {

    static defaultProps = {
        page: 1,
        onDocumentComplete: null,
        scale: 1,
        cMapUrl: '../node_modules/pdfjs-dist/cmaps/',
        cMapPacked: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            pdf: null,

        };
    }

    componentDidMount() {
        const {
            onDocumentComplete,
            page,
            type,
            blob
        } = this.props;

        // PdfJsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.0.943/pdf.worker.js';
        PdfJsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
        // PdfJsLib.getDocument({url: file, cMapUrl, cMapPacked}).then((pdf) => {
        if(type.toLowerCase().includes("pdf")) {
            PdfJsLib.getDocument({data: blob}).promise.then((pdf) => {
                this.setState({pdf: pdf});
                if(onDocumentComplete) {
                    onDocumentComplete(pdf._pdfInfo.numPages); // eslint-disable-line
                }
                pdf.getPage(page).then(p => this.drawPDF(p));
            });
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        const {page, scale} = this.props;
        const {pdf} = this.state;
        if(pdf != null && (prevProps.page !== page || prevProps.scale !== scale)) {
            pdf.getPage(page).then(p => this.drawPDF(p));
        }
    }

    render() {
        const {className} = this.props;
        return <canvas ref={(canvas) => { this.canvas = canvas; }} className={className}/>;
    }

    drawPDF(page) {
        const {scale} = this.props;
        const viewport = page.getViewport({scale: scale});
        const {canvas} = this;
        const canvasContext = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        const renderContext = {
            canvasContext,
            viewport,
        };
        page.render(renderContext);
    };
}

export default PDFViewer;